<template>
    <div class="user-listing" id="user-admin-index">

        <h2>Admin Users</h2>
        <div class="row no-gutters">
            <div class="form-search-wrap col-6 pr-4">
                <div class="choices-filter-group double">
                    <input type="text" name="keyword" id="keyword" placeholder="Enter a name, username, email or Member ID" v-model="query_keyword" >
                </div>
            </div>
            <div class="col-2 pt-2 ">
                <a class="pull-right clear-button no-border cursor-pointer" v-on:click.stop="clearSearch()"><close-icon></close-icon> Clear Search</a>
            </div>
        </div>

        <hr>

        <div class="users-table-wrap">
            <table id="sortable-table" class="nice-table table-with-full-hover">
                <thead>
                    <tr class="tableHeaderRow">
                        <th class="th-name th-90 cursor-pointer" v-on:click="setSort('imis')">IMIS ID <i v-if="sort == 'imis'" class="fa cursor-pointer" :class="(sort_order != 'desc') ? 'fa-caret-up' : 'fa-caret-down'"></i></th>
                        <th class="th-name th-200" v-on:click="setSort('last_name')">Name <i v-if="sort == 'last_name'" class="fa cursor-pointer" :class="(sort_order != 'desc') ? 'fa-caret-up' : 'fa-caret-down'"></i></th>
                        <th class="th-email th-250" v-on:click="setSort('email')">Email <i v-if="sort == 'email'" class="fa cursor-pointer" :class="(sort_order != 'desc') ? 'fa-caret-up' : 'fa-caret-down'"></i></th>
                        <th class="th-role th-100" v-on:click="setSort('role')">Role <i v-if="sort == 'role'" class="fa cursor-pointer" :class="(sort_order != 'desc') ? 'fa-caret-up' : 'fa-caret-down'"></i></th>
                        <th class="th-role th-60" v-on:click="setSort('member_type')">Member Type <i v-if="sort == 'member_type'" class="fa cursor-pointer" :class="(sort_order != 'desc') ? 'fa-caret-up' : 'fa-caret-down'"></i></th>
                        <th class="th-actions th-80">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :class="(index%2) ? 'odd' : 'even'" v-for="(user, index) in users.data">
                        <td><div>{{ user.imis }}</div></td>
                        <td><div>{{ user.first_name + ' ' +user.last_name }}</div></td>
                        <td><div>{{ user.email }}</div></td>
                        <td><div>{{ user.role }}</div></td>
                        <td><div>{{ user.member_type }}</div></td>
                        <td>
                            <div>
                                <a :href="'/user/'+ user.id +'/profile'"><editicon class="svg-24 mr-2"></editicon> </a>
                                <a v-if="user.role != 'admin'" :href="'/user/'+ user.id +'/impersonate'" @click.prevent="clearImpersonate('/user/'+ user.id +'/impersonate')"><eyeicon class="svg-24"></eyeicon> </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="users" @pagination-change-page="paginationCalculator" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing 1-{{to}} of {{ total }} users</div>
        <div class="dataTables_info" v-else>Showing 0 users</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="mr-2">Show</span>
                <select class="plain-select num-entries-select" v-model="num" @change="numUsersChanged()">
                    <option value="10">10 Users</option>
                    <option value="20">20 Users</option>
                    <option value="50">50 Users</option>
                    <option value="100">100 Users</option>
                    <option value="all">All Users</option>
                </select>
                <span>per page</span>
            </label>
        </div>


        <div class="change-mass-slide" :class="slideUpClass">
            <div class="container" style="margin-left: 0 !important;">
                <div class="bottom-border">
                    <a href="#" class="btn btn-primary pull-right change-mass-button modal-modal mr-5" @click.prevent="modalTriggerChangeEnMass = true;"><i class="fa double-arrow"></i>Change en masse</a>
                    <h2><span v-text="numEntriesSelected"></span> users selected</h2>
                    <a href="#" class="select-all-change" @click.prevent="selectAllCheckboxes()">Select All</a>
                </div>
                <a href="#" class="fa close-icon close-button" @click.prevent="slideUpClass = ''"></a>
            </div>
        </div>

    </div>

</template>

<script>
import modal from './Modal.vue';
import personicon from "@/Icons/person.vue";
import editicon from "@/Icons/edit.vue";
import eyeicon from "@/Icons/eye.vue";
import CloseIcon from "@/Icons/close.vue";

export default {
    name: "UsersTable",
    components: {CloseIcon, modal, personicon, editicon, eyeicon},
    data() {
        return {
            errors: false,
            num:20,
            page:1,
            total: 0,
            to: 0,
            sort:'',
            sort_order: 'desc',
            users: {},
            selectAllCheckbox: false,
            query_keyword: (localStorage.getItem('FYC-admin-users') != null) ? localStorage.getItem('FYC-admin-users') : '',
            modalTriggerChangeEnMass: false,
            slideUpClass: '',
            selectedUserIds: [],
            numEntriesSelected: 0,
            changeEnMass_machineName: '',
            changeEnMass_value: ''
        }
    },
    mounted() {
        let app = this;
        if(localStorage.getItem('FYC-admin-users-sort')) {
            app.sort = localStorage.getItem('FYC-admin-users-sort');
        }
        if(localStorage.getItem('FYC-admin-users-sort-order')) {
            app.sort_order = localStorage.getItem('FYC-admin-users-sort-order');
        }
        if(localStorage.getItem('FYC-admin-users-num')) {
            app.num = localStorage.getItem('FYC-admin-users-num');
        }
        if(localStorage.getItem('FYC-admin-users-page')) {
            app.page = localStorage.getItem('FYC-admin-users-page');
        }
        app.fetchUsers(app.page);
    },
    methods: {
        fetchUsers: function(page = 1) {
            let app = this;
            axios.get('/api/users', {
                params: {
                    num: app.num,
                    page: page,
                    sort: app.sort,
                    sort_order: app.sort_order,
                    keyword: app.query_keyword,
                }
            })
                .then(res => {
                    app.users = res.data;
                    app.total = res.data.total;
                    app.to = res.data.to;
                    localStorage.setItem('FYC-admin-users-to', app.to);
                    localStorage.setItem('FYC-admin-users', app.query_keyword);
                    localStorage.setItem('FYC-admin-users-num', app.num);
                    let userIdList = _.map(app.users.data, 'id')
                    localStorage.setItem('FYC-admin-users-next-prev', userIdList);
                }).catch(err => {
                let errorMessage = err.response.status + ' status error';
                app.$parent.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
            });
        },
        setSort(sort) {
            if (this.sort == sort) {
                this.sort_order = (this.sort_order == 'desc')?'asc':'desc';
            } else {
                this.sort = sort;
                this.sort_order = 'desc';
            }
            localStorage.setItem('FYC-admin-users-sort', this.sort);
            localStorage.setItem('FYC-admin-users-sort-order', this.sort_order);
            this.fetchUsers();
        },
        clearSearch: function() {
            this.query_keyword = '';
            this.sort = '';
            this.sort_order = 'desc';
            this.num = 20;
            localStorage.removeItem('FYC-admin-users');
            localStorage.removeItem('FYC-admin-users-type');
            localStorage.removeItem('FYC-admin-users-sort');
            localStorage.removeItem('FYC-admin-users-sort-order');
            localStorage.removeItem('FYC-admin-users-num');
            localStorage.removeItem('FYC-admin-users-page');
        },
        paginationCalculator: function(pageIndex) {
            localStorage.setItem('FYC-admin-users-page', pageIndex);
            this.fetchUsers(pageIndex);
        },
        selectAllCheckboxes: function() {
            let app = this;
            app.selectAllCheckbox = !app.selectAllCheckbox;
            app.selectedUserIds = [];
            app.users.data.forEach(function(user,i) {
                user.checkbox = app.selectAllCheckbox
                if(app.selectAllCheckbox) {
                    app.selectedUserIds.push(user.id);
                }
            });
            app.slideChangeEnMassDetails();
        },
        checkboxClicked: function(id) {
            let app = this;
            if(app.selectedUserIds.includes(id)) {
                const index = app.selectedUserIds.indexOf(id);
                if (index > -1) {
                    app.selectedUserIds.splice(index, 1);
                }
            } else {
                app.selectedUserIds.push(id);
            }
            app.slideChangeEnMassDetails();
        },
        slideChangeEnMassDetails: function() {
            let app = this;
            app.numEntriesSelected =  app.selectedUserIds.length
            if(app.numEntriesSelected > 0) {
                app.slideUpClass = 'slideUp';
            } else {
                app.slideUpClass = ' ';
            }
        },
        updateUsers: function() {
            let app = this;
            axios.get('/users/mass-update', {
                params: {
                    user_ids: app.selectedUserIds,
                    change_en_mass_machine_name: app.changeEnMass_machineName,
                    change_en_mass_machine_value: app.changeEnMass_value,
                }
            })
                .then(res => {
                    app.$parent.$refs.flash_alert.activateFlashAlert('Data saved, page being reloaded', 'success');
                    location.reload();
                }).catch(err => {
                app.modalTriggerChangeEnMass = false;
                app.errors = err.response.status + ' status error';
                app.$parent.$refs.flash_alert.activateFlashAlert(app.errors, 'error');
            });
        },
        numUsersChanged: function() {
            let app = this;
            localStorage.removeItem('FYC-admin-users-page');
            app.fetchUsers(1);
        },
        clearImpersonate(hrefVal) {
            localStorage.removeItem('FYC-search-sort');
            localStorage.removeItem('FYC-search-sort-order');
            localStorage.removeItem('FYC-search-num');
            localStorage.removeItem('FYC-search-page');
            window.location = hrefVal
        }
    },
    watch: {
        query_keyword() {
            // clear the pagination when the keyword is changing
            localStorage.removeItem('FYC-admin-users-page');
            this.fetchUsers(this.page);
        },
    }
}

</script>
