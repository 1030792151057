<template>
    <div class="mb-4">
        <div @click="collapsed = !collapsed" class="cursor-pointer profile-accordion border-bottom-1-grey6 d-flex align-items-center justify-content-between mb-3 toggle-with-up-icon text-decoration-none">
            <h3 class="primary mb-0">{{ title }}</h3>
            <down-chevron-icon class="float-end svg-24 svg-accent" v-if="collapsed"></down-chevron-icon>
            <up-chevron-icon class="float-end svg-24 svg-accent" v-else></up-chevron-icon>
        </div>

        <div class="collapse show profile-info-wrapper" id="collapseAboutEvent" v-show="!collapsed">
            <div class="event-accordion-content-wrapper mb-4 wysiwyg-content" v-html="content"></div>
                <div class="event-accordion-content-wrapper mb-4 wysiwyg-content" v-if="cohosted == 1 && cohosted_with == 'Other'">
                    <p ><span>Co-hosted with</span> {{ cohosted_with_details }}</p>
                </div>
                <div class="event-accordion-content-wrapper mb-4 wysiwyg-content" v-if="cohosted == 1 && cohosted_with != 'Other'">
                    <p ><span>Co-hosted with</span> {{ cohosted_with }}</p>
                </div>


            <div class="email-footer-conditional-terms pb-2">
                <p class="f-14" v-if="type != 'Virtual'">
                    You can always check the status of your RSVP <a href="https://emmys.com/members/fyc/events" class="no-click no-hover" @click.prevent="">here</a>.
                </p>
                <p class="f-14">
                    Questions? Visit the <a href="https://televisionacademy.com/faq/fyc" target="_blank" class="no-hover no-click" @click.prevent="">FYC FAQ</a> for answers.
                </p>
                <p class="f-14 mb-0" v-if="type == 'Virtual' && platform_used == 'Own Platform'">
                    Event link and details will be sent to registered attendees prior to the event.
                </p>
                <p class="mb-0 f-14 f-600" v-if="type == 'Hybrid' && platform_used == 'Own Platform'">
                    This event will also be presented online. Event link and details will be sent to registered members prior to the event.
                </p>
            </div>

            <div v-if="type != 'Virtual'">
                <p class="f-14">Entry is first come, first served. Admittance is not guaranteed. Only Television Academy Active/Voting Members will be admitted. Members must bring photo identification for check in.</p>
                <p class="f-14">The FYC event host reserves the right to invite guild members and other guests.</p>
                <p v-if="health_protocols" >{{ health_protocols }}</p>
                <p v-if="checkin_process_notes">{{ checkin_process_notes }}</p>
                <p v-if="ada_invitation_copy">{{ ada_invitation_copy }}</p>
            </div>
            <div class="f-14p" v-if="event_page_additional_content" v-html="event_page_additional_content"></div>
            <div class="f-14p text-uppercase">This event is presented and hosted by {{ network_platform }} not by the television academy</div>
        </div>
    </div>
</template>

<script>
import UpChevronIcon from "@/Icons/up-chevron.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";

export default {
    components: {
        DownChevronIcon,
        UpChevronIcon
    },
    props: {
        title: String,
        content: String,
        cohosted: Boolean,
        cohosted_with: String,
        cohosted_with_details: String,
        event_page_additional_content: String,
        platform_used: String,
        network_platform: String,
        type: String
    },
    data() {
        return {
            collapsed: false
        }
    }
}

</script>
