<template>
    <div>
        <div class="form-not-modals">
            <div class="col-12 border-bottom mb-4 pl-0 top-header-sticky">
                <div class="d-flex justify-content-between align-items-end pb-1">
                    <h1 class="mb-0">Viewing Platform Order</h1>
                    <div class="entry-info-number">
                        <div class="entry-id-wrapper d-flex align-items-end">
                            <div class="mr-4">
                                <label class="membership-type">Order Status</label>
                                <div class="membership-label" :class="orderinfo.order_status == 'submitted' ? 'submitted-color' : ''">
                                    <template v-if="role == 'admin'">
                                        {{ submission_statuses[orderinfo.order_status] }}
                                    </template>
                                    <template v-else>
                                        {{ submission_statuses_submitter.find(x => x.id === orderinfo.order_status).text }}
                                    </template>
                                </div>
                            </div>
<!--                            <div class="mr-4" v-if="orderinfo.asset_status">-->
<!--                                <label class="membership-type">Asset Status</label>-->
<!--                                <div class="membership-label" :class="orderinfo.asset_status == 'submitted' ? 'submitted-color' : ''">-->
<!--                                    {{ asset_statuses[orderinfo.asset_status] }}-->
<!--                                </div>-->
<!--                            </div>-->
                            <div>
                                <span class="label small-caps">FYC No.</span>
                                <span class="value" id="entryId">{{ orderinfo.order_id }}</span>
                            </div>
                            <div class="form-actions form-actions-edit" v-if="role == 'admin'">
                                <a href="#" @click.prevent="updateForm('','',true)" class="btn btn-primary btn-icon" id="SaveButton" v-if="role == 'admin'">
                                    <save-icon></save-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 mt-0 event-order-steps-menu d-flex justify-content-between align-items-start" v-if="role == 'admin'">
                <admin-statuses
                    type="order_vp"
                    :order="orderinfo"
                    :actionurl_details="actionurl_details"
                    :actionurl_assets="actionurl_assets"
                    :actionurl_send_at_time="actionurl_send_at_time"
                    :actionurl_team_assigned="actionurl_team_assigned"
                    :team_assigned_members="team_assigned_members"
                    :updateurl="actionurl"
                    :user_change_url="user_change_url"
                    :submission_statuses="submission_statuses"
                    :asset_statuses="asset_statuses"
                    :invoices="invoices"
                    :user_suggestions_url="user_suggestions_url"
                ></admin-statuses>
            </div>

            <ul class="accordion-tabs mb-5" v-if="role == 'admin'">
                <li class="nav-item d-inline-block">
                    <a class="tab-link is-active" :href="'/order/'+ orderinfo.order_id">Order Details</a>
                </li>
                <li class="nav-item d-inline-block">
                    <a class="tab-link" :href="'/order/'+ orderinfo.order_id + '/logs'">Change Log</a>
                </li>
                <li class="nav-item d-inline-block" v-if="orderinfo.category_type == 'program'">
                    <a class="tab-link" :href="'/order/'+ orderinfo.order_id + '/consolidation'">Content Consolidation</a>
                </li>
            </ul>

            <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-0">
                <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between" id="order-step--1">
                    <a @click.prevent="applicationStepSelector(0)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(0)">
                        <div class="position-absolute app-status-icons">
                            <Attention svg-class="svg-24 red-svg" v-if="iconArray.details && iconArray.details == 'incomplete'"></Attention>
                            <checkmark svg-class="svg-24 svg-green" v-if="iconArray.details && iconArray.details == 'complete'"></checkmark>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                            <h3 class="mb-0">Order Details</h3>
                            <div class="accordion-status-wrapper d-flex align-items-center w-25" :class="statusForIndicators.includes(orderinfo.order_status) ? 'justify-content-between' : 'justify-content-end'">
                                <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.order_status" v-if="statusForIndicators.includes(orderinfo.order_status)">
                                    <submitted svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'submitted'"></submitted>
                                    <question svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'need_more_info'"></question>
                                    <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'approved' || orderinfo.order_status == 'live' || orderinfo.order_status == 'live_checked' || orderinfo.order_status == 'approved_for_build'"></circle_check>
                                    <template v-if="role == 'admin'">
                                        <span class="text-uppercase">{{ submission_statuses[orderinfo.order_status] }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-uppercase">{{ submission_statuses_submitter.find(x => x.id === orderinfo.order_status).text }}</span>
                                    </template>
                                </div>
                                <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 0) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                            </div>
                        </div>
                    </a>
                </div>
                <div id="accordion-order-details" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(orderinfo.application_step == null || orderinfo.application_step == 0) ? 'show' : ''">
                    <div class="mt-3 fields-wrapper-row pl-30">
                        <div class="mt-5 full-instructions-wrapper fields-wrapper-row">
                            <h3 class="order-header">Submitter Contact</h3>
                            <div class="form-group-beside row" :class="errorObject['details']['your_name'] ? 'required' : ''">
                                <label for="your_name" class="col-2 col-md-6">Your Name: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','your_name',false)" type="text" v-model="orderinfo.your_name" name="your_name" id="your_name" class="form-control your_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['your_email'] ? 'required' : ''">
                                <label for="your_email" class="col-2 col-md-6">Your Email: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','your_email',false)" type="email" v-model="orderinfo.your_email" id="your_email" class="form-control your_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['your_phone'] ? 'required' : ''">
                                <label for="your_phone" class="col-2 col-md-6">Your Phone: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','your_phone',false)" type="text" v-model="orderinfo.your_phone" id="your_phone" class="form-control your_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                        </div>
                        <div class="full-instructions-wrapper fields-wrapper-row">
                            <h3 class="order-header mb-2">Viewing Platform Order Details</h3>

                            <div class="radio-buttons-toggle-wrapper mb-4" :class="errorObject['details']['category_type'] ? 'required' : ''">
                                <div class="ml-4 pb-2 mt-3 col-12 col-md-9 d-flex align-items-center justify-content-between mt-2">
                                    <div class="custom-control custom-radio d-flex align-items-center pl-0 pr-5">
                                        <input v-if="update_allowed['details']" @change="createCategorySelectList('program', false)" type="radio" v-model="orderinfo.category_type" value="program" class="custom-control-input" id="orderinfo.category_type" name="orderinfo.category_type">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.category_type == 'program'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.category_type">
                                            Program Categories
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                        <input v-if="update_allowed['details']" @change="createCategorySelectList('individual', false)" type="radio" v-model="orderinfo.category_type" value="individual" class="custom-control-input" id="orderinfo.category_type_no" name="orderinfo.category_type">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.category_type == 'individual'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.category_type_no">
                                            Individual Achievement Categories
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="orderinfo.category_type">
                                <div class="form-group-beside row" :class="errorObject['details']['category'] ? 'required' : ''">
                                    <label :for="'category'" class="col-2 col-md-6">Category: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                      <v-select  v-if="update_allowed['details']" id="category" @input="updatePrice(orderinfo.category); updateForm('details','category',false)" placeholder="Select Category" v-model="orderinfo.category" label="text" :reduce="text => text.id" :options="categoryOptions" ></v-select>
                                        <span class="readonly pl-2" v-text="orderinfo.category_name" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>
                                <div class="form-group-beside row" :class="errorObject['details']['program_name'] ? 'required' : ''">
                                    <label for="program" class="col-2 col-md-6">Program Name: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','program_name',false)" type="text" v-model="orderinfo.program_name" id="program" autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control program" @blur="titleCaseBlur" @keyup="titleCase('program_name', $event)" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row program_logo_preview_wrapper" :class="errorObject['details']['program_logo_preview'] ? 'required' : ''">
                                    <label for="program_logo_preview" class="col-2 col-md-6">
                                        <div>Program Logo: <em class="yellow no-style">*</em></div>
                                        <div class="description">Must be square, 600x600px minimum, .jpg or .png<br>Image (logo or key art) must include program title and cannot have a transparent background.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <fyc-cropper
                                            v-if="update_allowed['details']"
                                            indexval="100"
                                            :initval="orderinfo.program_logo_preview"
                                            min-crop-width="600"
                                            min-crop-height="600"
                                            @deleteImage="deleteImage('program_logo_preview')"
                                            @croppedImage="saveForValidation"
                                            :file-size-restriction="true"
                                            machine_name="program_logo_preview"
                                            :updating-form="updatingForm"
                                            :role="role"
                                        >
                                        </fyc-cropper>
                                        <div class="small-img image-available" v-if="!update_allowed['details']">
                                            <img :src="orderinfo.program_logo_preview">
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group-beside row" :class="errorObject['details']['headshot_preview'] ? 'required' : ''" v-if="conditionalHeadshot">
                                    <label for="headshot_preview" class="col-2 col-md-6">
                                        <div>Headshot: <em class="yellow no-style">*</em></div>
                                        <div class="description">Must be square image, 600x600px minimum, .jpg or .png, not on a transparent background.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <fyc-cropper
                                            v-if="update_allowed['details']"
                                            indexval="101"
                                            :initval="orderinfo.headshot_preview"
                                            min-crop-width="600"
                                            min-crop-height="600"
                                            @deleteImage="deleteImage('headshot_preview')"
                                            @croppedImage="saveForValidation"
                                            :file-size-restriction="true"
                                            machine_name="headshot_preview"
                                            :updating-form="updatingForm"
                                            :role="role"
                                        >
                                        </fyc-cropper>
                                        <div class="small-img image-available" v-if="!update_allowed['details']">
                                            <img :src="orderinfo.headshot_preview">
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group-beside row" :class="errorObject['details']['emmy_number'] ? 'required' : ''">
                                    <label for="emmy_number" class="col-2 col-md-6">Emmy Entry Number:
                                        <div class="description">If you’ve already created your Emmy submission, list your 7-digit entry number here.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','emmy_number',false)" type="text" v-model="orderinfo.emmy_number" id="emmy_number" class="form-control program_name" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row"  :class="errorObject['details']['video_for'] ? 'required' : ''" v-if="orderinfo.category_type != 'program'">
                                    <label for="video_for" class="col-2 col-md-6">
                                        Video is for: <em class="yellow no-style">*</em>
                                        <div class="description">
                                            Please list entrant’s name. Ex: Performer’s name or for teams of more than two list as: Art Direction Team, Makeup Team, etc.
                                        </div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','video_for',false)" type="text" v-model="orderinfo.video_for" id="video_for"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control video_for" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row select-box-bigger" :class="errorObject['details']['cable_network'] ? 'required' : ''">
                                    <label :for="'cable_network'" class="col-2 col-md-6">
                                        Broadcast/Cable Network, Studio or Platform: <em class="yellow no-style">*</em>
                                        <div class="description">If not listed or submitting for studio, select other.<br/></div>
                                       </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                      <v-select v-if="update_allowed['details']" id="cable_network" placeholder="Select option" v-model="orderinfo.cable_network" :options="cable_network_options" @input="updateForm('details','cable_network',false)"></v-select>
                                        <span class="readonly" v-text="orderinfo.cable_network" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>
                                <div class="form-group-beside row" v-if="orderinfo.cable_network == 'Other'" :class="errorObject['details']['cable_network_other'] ? 'required' : ''">
                                    <label for="cable_network_other" class="col-2 col-md-6">If other, list here: </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','cable_network_other',false)" type="text" v-model="orderinfo.cable_network_other" id="cable_network_other" class="form-control cable_network_other" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                                <div class="form-group-beside row" :class="errorObject['details']['cable_network_logo_preview'] ? 'required' : ''">
                                    <label for="cable_network_logo_preview" class="col-2 col-md-6">
                                        <div>Broadcast/Cable Network, Studio or Platform Logo: <em class="yellow no-style">*</em></div>
                                        <div class="description">Must be square image, 600x600px minimum, .jpg or .png, not on a transparent background.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <fyc-cropper
                                            v-if="update_allowed['details']"
                                            indexval="102"
                                            :initval="orderinfo.cable_network_logo_preview"
                                            min-crop-width="600"
                                            min-crop-height="600"
                                            @deleteImage="deleteImage('cable_network_logo_preview')"
                                            @croppedImage="saveForValidation"
                                            :file-size-restriction="true"
                                            machine_name="cable_network_logo_preview"
                                            :updating-form="updatingForm"
                                            :role="role"
                                        >
                                        </fyc-cropper>
                                        <div class="small-img image-available" v-if="!update_allowed['details']">
                                            <img :src="orderinfo.cable_network_logo_preview">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group-beside row" :class="errorObject['details']['self_published'] ? 'required' : ''">
                                    <label for="self_published" class="col-2 col-md-6">
                                        Was there a network, studio, or streaming platform financially or creatively involved in this program? <em class="yellow no-style">*</em>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <div class="toggle-js">
                                            <span class="yes-toggle" :class="toggleClass['self_published']" @click="update_allowed['details'] ? toggleFunctionality('self_published',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                            <span class="no-toggle" :class="toggleClass['self_published']" @click="update_allowed['details'] ? toggleFunctionality('self_published',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                        </div>
                                        <input @change="updateForm('details','self_published',false)" type="hidden" v-model="orderinfo.self_published" id="self_published" class="form-control self_published">
                                    </div>
                                    <div class="col-12 offset-md-6 col-md-6" v-if="orderinfo.self_published == 0">
                                        <p>This program will be vetted to determine eligibility. Submit your order but do not checkout and pay for it yet. You will be notified once vetting has been completed.</p>
                                    </div>
                                </div>

                                <div class="form-group-beside row" v-if="orderinfo.self_published == 1" :class="errorObject['details']['self_published_network'] ? 'required' : ''">
                                    <label for="self_published_network" class="col-2 col-md-6">If yes, list the network, studio, or streaming platform here: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','self_published_network',false)" type="text" v-model="orderinfo.self_published_network" id="self_published_network" class="form-control self_published_network" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                                <h3 class="order-header mt-5">Video Uploads</h3>
                                <div class="form-group-beside row" :class="errorObject['details']['third_party_uploader'] ? 'required' : ''">
                                    <label for="third_party_uploader" class="col-2 col-md-6">
                                        Will someone upload on your behalf? <em class="yellow no-style">*</em>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <div class="toggle-js">
                                            <span class="yes-toggle" :class="toggleClass['third_party_uploader']" @click="update_allowed['details'] ? toggleFunctionality('third_party_uploader',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                            <span class="no-toggle" :class="toggleClass['third_party_uploader']" @click="update_allowed['details'] ? toggleFunctionality('third_party_uploader',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                        </div>
                                        <input @change="updateForm('details','third_party_uploader',false)" type="hidden" v-model="orderinfo.third_party_uploader" id="third_party_uploader" class="form-control third_party_uploader">
                                    </div>
                                </div>

                                <div class="third_party_uploader_wrapper" v-if="orderinfo.third_party_uploader == 1">
                                    <div class="form-group-beside row" :class="errorObject['details']['uploader_name'] ? 'required' : ''">
                                        <label for="uploader_name" class="col-2 col-md-6">Uploader Name: <em class="yellow no-style">*</em></label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                            <input @change="updateForm('details','uploader_name',false)" type="text" v-model="orderinfo.uploader_name" name="uploader_name" id="uploader_name" class="form-control uploader_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                        </div>
                                    </div>
                                    <div class="form-group-beside row" :class="errorObject['details']['uploader_email'] ? 'required' : ''">
                                        <label for="uploader_email" class="col-2 col-md-6">Uploader Email: <em class="yellow no-style">*</em></label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                            <input @change="updateForm('details','uploader_email',false)" type="email" v-model="orderinfo.uploader_email" id="uploader_email" class="form-control uploader_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                        </div>
                                    </div>
                                    <div class="form-group-beside row" :class="errorObject['details']['uploader_phone'] ? 'required' : ''">
                                        <label for="uploader_phone" class="col-2 col-md-6">Uploader Phone: <em class="yellow no-style">*</em></label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                            <input @change="updateForm('details','uploader_phone',false)" type="text" v-model="orderinfo.uploader_phone" id="uploader_phone" class="form-control uploader_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group-beside row" v-if="orderinfo.third_party_uploader == 1">
                                    <label for="third_party_uploader_message" class="col-2 col-md-6">Notes to Uploader
                                        <div class="description">Ex: list the episode(s) to be uploaded</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <textarea @change="updateForm('details','third_party_uploader_message',false)" rows="5" type="text" id="third_party_uploader_message" class="form-control" v-model="orderinfo.third_party_uploader_message" :disabled="(role == 'viewer')?true:false"></textarea>
                                    </div>
                                </div>

                                <div class="row mt-2" v-if="orderinfo.uploader_email && update_allowed['details'] && orderinfo.third_party_uploader == 1 ">
                                    <label class="border-0 col-2 col-md-6">
                                        <div> </div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <div v-show="notifyUploaderStatus" :class="notifyUploaderStatus" v-html="notifyUploaderStatusMessage"></div>
                                        <button @click.prevent="notifyUploader()"><mail-icon class="svg-black"></mail-icon> Send upload request email</button>
                                    </div>
                                </div>

                                <div class="mt-3" :class="errorObject['details'] && errorObject['details']['video_file_objects'] ? 'required' : ''">
                                    <video-component-upload
                                        ref="videoComponent"
                                        :role="(orderinfo.is_approved?'viewer':role)"
                                        :updateallowed="update_allowed['details']"
                                        :initialdata="[]"
                                        :isadmin="role==='admin'"
                                        :videoobject="orderinfo.video_file_objects"
                                        :bucketname="bucketname"
                                        :entryid="orderinfo.id"
                                        :nominee_video_status="orderinfo.nominee_video_status"
                                        :submitterid="orderinfo.user_id"
                                        :add_another_video_allowed="addAnotherVideoAllowed"
                                        :videofilestatuses="videofilestatuses"
                                        :hold_until_min_date="hold_until_min_date"
                                        :hold_until_max_date="hold_until_max_date"
                                        machine_name="video_order_file"
                                        type="order_vp"
                                        :errorObject="errorObject"
                                        @savingVideoData="savingVideoData"
                                        @upload-started="videoUploadsInProgress++"
                                        @upload-finished="videoUploadsInProgress--"
                                    ></video-component-upload>
                                </div>

                                <section class="review-notes mt-5">
                                    <h2>Acknowledgement and FYC Submission</h2>
                                    <div class="acknowledgement-description wysiwyg-content" v-html="order_acknowledgement"></div>

                                    <div class="signature-block" @click.prevent="signatureNeededPopover()">
                                        <div class="btn btn-primary btn-big" v-show="!orderinfo.signature" v-if="update_allowed['details']">
                                            <checkbox_empty></checkbox_empty> Click to sign
                                        </div>
                                        <img :src="orderinfo.signature" />
                                    </div>
                                    <div class="red warning" v-show="!orderinfo.signature">Order can only be submitted after all of the required information has been completed and the acknowledgement has been signed.</div>
                                </section>
                            </div>
                            <div class="d-flex" :class="orderinfo.category_type == 'individual' ? 'justify-content-between' : 'justify-content-end'">
                                <div v-if="orderinfo.category_type == 'individual'">
                                </div>

                                <div class="text-right" v-if="orderinfo.category_type">
                                    <h2 class="pt-5 text-right" v-if="orderinfo.category != ''">Total price: ${{ orderinfo.cost }}</h2>
                                    <button @click.prevent="SaveApplicationStep(1)" v-if="update_allowed['details'] && role != 'admin'" v-bind:disabled="!orderinfo.signature || updatingForm" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                        <div>
                                            <span class="submit-text">Approve Order Details</span>
                                            <span>& Submit For Review</span>
                                        </div>
                                        <right-small></right-small>
                                    </button>

                                    <a v-if="role === 'admin'" @click.prevent="updateForm('','',true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                        <save-icon></save-icon>
                                        <div>
                                            <span class="submit-text">Save Order</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-1" v-if="orderinfo.category_type == 'program'">
                <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between">
                    <a @click.prevent="applicationStepSelector(1)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(1)">
                        <div class="position-absolute app-status-icons">
                            <Attention svg-class="svg-24 red-svg" v-if="iconArray.assets && iconArray.assets == 'incomplete'"></Attention>
                            <checkmark svg-class="svg-24 svg-green" v-if="iconArray.assets && iconArray.assets == 'complete'"></checkmark>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                            <h3 class="mb-0">Viewing Platform Assets</h3>
                            <div class="accordion-status-wrapper d-flex align-items-center w-33" :class="statusForIndicatorsAssets.includes(orderinfo.asset_status) ? 'justify-content-between' : 'justify-content-end'">
                                <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.asset_status" v-if="statusForIndicatorsAssets.includes(orderinfo.asset_status)">
                                    <submitted svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'submitted'"></submitted>
                                    <question svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'need_more_info'"></question>
                                    <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'approved' || orderinfo.asset_status == 'building' || orderinfo.asset_status == 'published'"></circle_check>
                                    <span class="text-uppercase">{{ orderinfo.asset_status.replace(/_/g, ' ') }}</span>
                                </div>
                                <div class="d-flex align-items-center cursor-initial" v-else-if="orderinfo.emails && orderinfo.emails.some(email => email.email_status == 'need_more_info')">
                                    <div class="d-flex align-items-center cursor-initial pr-2 status-color-need_more_info">
                                        <question svg-class="svg-24 mr-2"></question>
                                        <span class="text-uppercase">Emails Need More Info</span>
                                    </div>
                                </div>

                                <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 1) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                            </div>

                        </div>
                    </a>
                </div>

                <div id="accordion-order-assets" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="orderinfo.application_step == 1 ? 'show' : ''">
                    <div class="full-instructions-wrapper fields-wrapper-row pl-30">
                        <h3 class="order-header">Contact For Viewing Platform Assets</h3>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_name'] ? 'required' : ''">
                            <label for="email_asset_contact_name" class="col-2 col-md-6">Email Asset Contact Name: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_name', false)" type="text" v-model="orderinfo.email_asset_contact_name" name="email_asset_contact_name" id="email_asset_contact_name" class="form-control email_asset_contact_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_email'] ? 'required' : ''">
                            <label for="email_asset_contact_email" class="col-2 col-md-6">
                                Email Asset Contact Email: <em class="yellow no-style">*</em>
                                <div class="description pl-4 mt-1" v-if="errorObject['assets'] && errorObject['assets']['email_asset_contact_email']"><p class="red-font">{{ errorObject['assets']['email_asset_contact_email'][0] }}</p></div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_email', false)" type="email" v-model="orderinfo.email_asset_contact_email" id="email_asset_contact_email" class="form-control email_asset_contact_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_phone'] ? 'required' : ''">
                            <label for="email_asset_contact_phone" class="col-2 col-md-6">Email Asset Contact Phone: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_phone', false)" type="text" v-model="orderinfo.email_asset_contact_phone" id="email_asset_contact_phone" class="form-control email_asset_contact_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="pt-2 mb-5 d-flex justify-content-end align-items-center" v-if="role == 'admin'">
                            <a @click.prevent="updateForm('','',true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                <save-icon></save-icon>
                                <div>
                                    <span class="submit-text">Save Order</span>
                                </div>
                            </a>
                        </div>


                        <h3 class="order-header mt-5 mb-3">FYC Viewing Platform Emails</h3>
                        <div class="description" v-html="order_vp_email_description">
                        </div>

                        <template v-for="month in ['March', 'April', 'May', 'June']">
                            <order-vp-email
                                @submitEmail="emailSyncWithOrder"
                                @hasEmailStarted="hasEmailStarted"
                                :excluded_dates="disabled_dates"
                                :month="month"
                                :active="email_component_accessible[month]"
                                :orderinfo="orderinfo"
                                :email_statuses="asset_statuses"
                                :update_allowed="update_allowed"
                                :role="role"
                                :viewing_platform_expiry_date="viewing_platform_expiry_date"
                                order_type="vp" />
                        </template>

                    </div>
                </div>

            </div>
            <div v-if="emailsStatuses && Object.keys(emailsStatuses).every(key => emailsStatuses[key])" class="form-actions form-actions-edit border-top-2-dark mt-5 pt-5">
                <div class="d-flex justify-content-end">
                    <a href="/checkout" class="btn btn-primary btn-step-next">
                        <span class="submit-text">Checkout</span>
                    </a>
                </div>
            </div>
            <div class="form-actions form-actions-edit border-top-2-dark mt-5 pt-5" v-else-if="role == 'submitter'">
                <div v-if="update_allowed['assets']" class="d-flex flex-row-reverse justify-content-between">
                    <button @click.prevent="saveForLater" class="save-button btn btn-primary" id="SaveButton">
                        <save-icon v-if="!updatingForm && !this.videoUploadsInProgress"></save-icon>
                        <i v-else class="fa fa-spinner fa-spin p-1 mr-2"></i>
                        Save for Later
                    </button>
                    <button class="btn btn-delete" @click.prevent="deleteModal = true" v-if="orderinfo.order_status != 'approved' && orderinfo.order_status != 'canceled'">Delete</button>
                </div>
            </div>
        </div>

        <div class="justify-content-between mt-3" v-if="false">
            <button class="btn btn-primary" @click.prevent="reportOrderIssue = true">Report Order Issue</button>
        </div>

        <modal v-if="deleteModal" @close="deleteModal = false">
            <h3 slot="header">Delete Submission</h3>
            <div class="content" slot="body">
                <p>Are you sure?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="deleteModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteOrder">Yes, Delete</button>
            </div>
        </modal>

        <modal v-if="signatureNeeded" @close="signatureNeeded = false" modalclass="modal-signature">
            <h2 class="description" slot="header">Sign in the window below using your track pad or mouse</h2>
            <div class="content" slot="body">
                <signature-pad @closeModalClicked="closeModal" @signatureSave="saveSignature"></signature-pad>
            </div>
            <div class="form-group-submit" slot="footer">
            </div>
        </modal>

        <modal v-if="showSavingModal" @close="showSavingModal = false" class="savingmodal">
            <h1 slot="header" v-text="" v-show="false"></h1>
            <div class="ballot-submitting-modal-content content" slot="body">
                <img :src="`/images/animation02.gif`">
            </div>
            <div class="form-group-submit" slot="footer" v-show="false">
            </div>
        </modal>

        <modal class="limited-width-modal" v-if="showOrderDetailsCompletePopup" @close="showOrderDetailsCompletePopup = false">
            <h3 slot="header">Submit Viewing Platform Order for Approval</h3>
            <div class="content" slot="body">
                <p v-if="orderinfo.category_type == 'program'">
                    Please click below to submit. Upon submission, the Television Academy FYC team will review your order and email details.
                    The tech team will reach out if there are any issues with your video file(s).
                    Meanwhile, please continue by selecting your invitation send date and providing the required assets in the next section.
                </p>
                <div v-else>
                    <p>Please click below to submit. Upon submission, the Television Academy FYC team will review your order details.
                    The tech team will reach out if there are any issues with your video file.</p>
                    <p>Next step: Go to the checkout page to confirm and pay.</p>
                </div>
            </div>
            <div class="form-group-submit" slot="footer">
                <a class="btn btn-cancel pull-left" @click="showOrderDetailsCompletePopup = false">Close</a>
                <button class="btn btn-primary pull-right" @click.prevent="submitForm()">Submit Now</button>
            </div>
        </modal>

        <modal v-if="showCategoryTypeChangeConfirmationModal" @close="showCategoryTypeChangeConfirmationModal = false">
            <h3 slot="header">Change Category Type</h3>
            <div class="content" slot="body">
                <p>If you had any emails in progress they will be deleted</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="revertCategoryChange()">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteEmailsFromCategoryType">Yes, Delete</button>
            </div>
        </modal>

        <modal v-if="reportOrderIssue" @close="reportOrderIssue = false" clickoutside="true">
            <h3 slot="header">Report Order Issue</h3>
            <div class="content" slot="body">
                <div class="select">
                    <select id="submitter_issue_status" v-model="orderinfo.submitter_issue_status" class="plain-select form-control">
                        <option :selected="orderinfo.submitter_issue_status" v-for="(value, key) in submitter_issue_statuses" :value="key" v-text="value"></option>
                    </select>
                    Issue Details:
                    <textarea rows="5" type="text" id="submitter_issue_details" v-model="orderinfo.submitter_issue_details" class="form-control" ></textarea>

                </div>
            </div>
            <div class="form-group-submit d-flex justify-content-between align-items-center" slot="footer">
                <button class="btn btn-primary btn-transparent" @click.prevent="reportOrderIssue = false">Close</button>
                <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-primary" @click.prevent="submitOrderIssue()">Submit</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
    // register modal component
    import modal from '../components/Modal.vue';
    import FycCropper from '../components/FycCropper.vue';
    import SaveIcon from "@/Icons/save.vue";
    import Checkmark from "@/Icons/checkmark.vue";
    import Attention from "@/Icons/attention.vue";
    import LockIcon from "@/Icons/lock.vue";
    import Circle_check from "@/Icons/circle_check.vue";
    import UpChevronIcon from "@/Icons/up-chevron.vue";
    import Question from "@/Icons/question.vue";
    import Submitted from "@/Icons/submitted.vue";
    import OrderVpEmail from "@/components/ViewingPlatform/OrderVpEmail.vue";
    import AdminOrderNextPrev from "@/components/AdminOrderNextPrev.vue";
    import MailIcon from "@/Icons/mail.vue";
    import TrashIcon from "@/Icons/trash.vue";
    import CircleFilledIcon from "@/Icons/circle_filled.vue";
    import Checkbox_empty from "@/Icons/checkbox_empty.vue";

    export default {
        components: {
            Checkbox_empty,
            CircleFilledIcon,
            TrashIcon,
            MailIcon,
            Submitted,
            Question,
            UpChevronIcon,
            Circle_check,
            LockIcon,
            Attention,
            Checkmark,
            SaveIcon,
            modal,
            FycCropper,
            OrderVpEmail,
            AdminOrderNextPrev
        },
        props: {
            navigation: Object,
            actionurl: String,
            actionurl_email_delete: String,
            actionurl_team_assigned: String,
            order: Array,
            ordersurl: String,
            categories: Array,
            role: String,
            update_allowed: Array,
            bucketname: String,
            videofilestatuses: Array,
            hold_until_min_date: String,
            hold_until_max_date: String,
            submission_statuses: Array,
            asset_statuses: Array,
            team_assigned_members: Array,
            order_acknowledgement: String,
            order_vp_video_upload: String,
            order_vp_email_description: String,
            actionurl_details: String,
            actionurl_assets: String,
            user_change_url: String,
            invoices: Array,
            user_suggestions_url: String,
            email_component_accessible: Array,
            myorders_url: String,
            viewing_platform_expiry_date: String,
            excluded_dates: Array,
            report_issue_url: String,
            submitter_issue_statuses: Array,
            cable_network_options: Array,
        },
        data() {
            return {
                allowedStatusForEditing: ['in_progress', 'need_more_info'],
                statusForIndicators: ['need_more_info', 'submitted', 'approved','live','live_checked', 'approved_for_build'],
                statusForIndicatorsAssets: ['need_more_info', 'submitted', 'approved', 'building', 'published', 'build_complete'],
                emailStatusOptionsBeforeCheckout: ['in_progress', 'need_more_info', 'canceled'],
                orderinfo: this.order,
                signatureNeeded: '',
                deleteModal: false,
                notifyUploaderStatusMessage: '',
                notifyUploaderStatus: '',
                categoryOptions: [],
                conditionalHeadshot: false,
                showSavingModal: false,
                reportOrderIssue: false,
                updatingForm: false,
                pendingFormUpdate: false,
                videoUploadsInProgress: 0,
                emailsStatuses: {
                    'March': false,
                    'April': false,
                    'May': false,
                    'June': false,
                },
                hasEmails: false,
                showCategoryTypeChangeConfirmationModal: false,
                addAnotherVideoAllowed: false,
                videoComponentEmpty: false,
                toggleClass: {
                    self_published: '',
                    third_party_uploader: ''
                },
                iconArray: {
                    'details': {},
                    'assets': {},
                    'post_event': {}
                },
                errorObject: {
                    'details': {},
                    'assets': {},
                    'post_event': {}
                },
                savingData: false,
                inputStepClicked: '',
                emailArray: {},
                showOrderDetailsCompletePopup: false,
                errorCodes: [401, 403, 419],
                disabled_dates: this.excluded_dates,
                componentKey: 1,
                submission_statuses_submitter: [{
                    text: "In Progress",
                    id: "in_progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Approved",
                    id: "approved_for_build"
                }, {
                    text: "Approved",
                    id: "approved"
                }, {
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live",
                    id: "live_checked"
                },{
                    text: "Need More Information",
                    id: "need_more_info"
                },
                {
                    text: "User Reported",
                    id: "user_reported"
                } ],
            }
        },
        mounted: function() {
            // Collapse accordion by defaults when in admin view
            if (this.role === 'admin') {
                this.orderinfo.application_step = -1;
            }

            this.createCategorySelectList(this.orderinfo.category_type, true);
            if(this.orderinfo.cost == null) {
                this.orderinfo.cost = 0;
            }

            // Loop through all items in toggleClass and run the toggleFunctionality method
            let orderSection = '';
            for (let key in this.toggleClass) {
                let orderSection = 'details';
                this.toggleFunctionality(key, this.orderinfo[key], 'onload', orderSection);
            }

            if(! this.update_allowed['details']) {
                this.inputReadOnly('#accordion-order-details');
            }

            if(! this.update_allowed['assets']) {
                this.inputReadOnly('#accordion-order-assets');
            }

            this.emailStatusesCreator();

            if(this.orderinfo.category) {
                this.updatePrice(this.orderinfo.category);
            }

            this.iconArrayConfiguration()
        },
        methods: {
            notifyUploader() {
                let that = this;
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                that.notifyUploaderStatus = 'success';
                that.notifyUploaderStatusMessage = 'Sending...';
                window.axios(
                    {
                        method: 'put',
                        url: this.actionurl,
                        data: {
                            'third_party_uploader_message': that.orderinfo.third_party_uploader_message,
                            'notifyUploader' : that.orderinfo.uploader_email}
                    }).then(function(response) {
                        that.notifyUploaderStatus = 'success';
                        that.notifyUploaderStatusMessage = 'Success: '+ response.data;
                        that.updatingForm = false;
                }).catch(function (err) {
                    if(err.response && that.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    that.notifyUploaderStatus = 'error';
                    that.notifyUploaderStatusMessage = 'Error: '+err.data;
                    that.updatingForm = false;
                });
            },
            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            },
            async onSubmit() {
                let that = this;
                if(that.role == 'viewer') {
                    return false;
                }
                let videoComponentFieldsRequired = false;
                if(this.orderinfo.video_file_objects.length > 0) {
                    videoComponentFieldsRequired = this.$refs.videoComponent.underlineFields();
                    that.videoComponentEmpty = false;
                } else {
                    that.videoComponentEmpty = true;
                }
                that.showSavingModal = true;

                if (that.videoComponentEmpty || videoComponentFieldsRequired) {
                    that.showSavingModal = false;
                    that.$emit('showflashalert');
                    that.$scrollTo('#top-of-form', 700);
                    return;
                }
                this.submitForm();
            },
            updateForm: function(field_group, field_name, adminSave) {
                let that = this;

                if ((this.role === 'admin') && (adminSave !== true)) {
                    return false;
                }

                // Remove required field 'required' indicator if field is filled in
                if (field_group && field_name) {
                    this.errorObject[field_group][field_name] = '';
                }

                if (this.updatingForm) {
                    this.pendingFormUpdate = true;
                    return;
                }

                this.updatingForm = true;

                window
                    .axios({
                        method: 'put',
                        url: this.actionurl,
                        data: that.orderinfo
                    })
                    .then(function(response) {
                        let dataBack = response.data;
                        if((that.role === 'admin') || (adminSave)) {
                            location.reload();
                        }

                        that.disabled_dates = dataBack.excluded_dates;

                        // If the network is changed, reload the page. We do this so the email subject line can be updated with the new network name.
                        if(dataBack.emails_started > 0 && (field_name == 'cable_network' || field_name == 'cable_network_other')) {
                            location.reload();
                        }
                    })
                    .catch(function (err) {
                        if(err.response && that.errorCodes.includes(err.response.status)) {
                            window.location.reload();
                        }
                        if (err.response) {
                            let errorMessage = err.response.data.message;
                            that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                            that.$emit('showflashalert');
                            that.$scrollTo('#top-of-form', 700);
                        }
                    })
                    .finally(() => {
                        this.updatingForm = false;
                        if (this.pendingFormUpdate) {
                            this.pendingFormUpdate = false;
                            this.updateForm();
                        }
                    });
            },
            submitForm: function() {
                let that = this;
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                that.orderinfo.form_status = 'submitted';
                window
                    .axios({
                        method: 'put',
                        url: this.actionurl,
                        data: that.orderinfo
                    })
                    .then(function(response) {
                        let dataBack = response.data;
                        if(dataBack.redirect_destination) {
                            window.location.href = dataBack.redirect_destination;
                        }
                        that.updatingForm = false;
                    })
                    .catch(function (err) {
                        if(err.response && that.errorCodes.includes(err.response.status)) {
                            window.location.reload();
                        }
                        that.showSavingModal = false;
                        that.$scrollTo('#top-of-form', 700);
                        that.updatingForm = false;
                    });
            },
            deleteOrder: function() {
                let that = this;
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                window
                    .axios({
                        method: 'delete',
                        url: this.actionurl,
                        data: that.orderinfo
                    })
                    .then(function(response) {
                        let dataBack = response.data;
                        window.location.href = dataBack.redirect_to;
                        that.updatingForm = false;
                    })
                    .catch(function (err) {
                        if(err.response && that.errorCodes.includes(err.response.status)) {
                            window.location.reload();
                        }
                        that.updatingForm = false;
                    });
            },
            signatureNeededPopover: function() {
                let that = this;
                if(!that.update_allowed['details']) {
                    return false;
                }
                document.body.classList.add("overlay");
                that.signatureNeeded = true;
            },
            closeModal: function() {
                document.body.classList.remove("overlay");
                this.signatureNeeded = false;
            },
            saveSignature: function(signature) {
                this.orderinfo.signature = signature;
                this.signatureNeeded = false;
                this.updateForm('details','signature',false);
            },
            saveForValidation: function(filename, field_name, fileData) {
                this.orderinfo[field_name] = fileData;

                let field_group = '';
                if((field_name == 'cable_network_logo_preview') || (field_name == 'program_logo_preview') || (field_name == 'headshot_preview')) {
                    field_group = 'details';
                } else {
                    field_group = 'assets';
                }
                this.updateForm(field_group, field_name,false);
            },
            iconArrayConfiguration: function() {
                if((! this.allowedStatusForEditing.includes(this.orderinfo.order_status)) && (this.orderinfo.order_status != 'canceled')) {
                    this.iconArray['details'] = 'lock'
                }
                if((! this.allowedStatusForEditing.includes(this.orderinfo.asset_status)) && (this.orderinfo.asset_status != 'canceled')) {
                    this.iconArray['assets'] = 'lock'
                }
            },
            hasEmailStarted: function(val) {
                this.hasEmails = true;
                this.orderinfo.emails.push(val ?? {})
            },
            createCategorySelectList: function(category_type, onload) {
                let that = this;

                if(!onload) {
                    that.orderinfo.category = '';
                    that.updateForm('details','category',false)
                    if((this.orderinfo.emails && this.orderinfo.emails.length > 0 || that.hasEmails) && (this.orderinfo.category_type == 'individual')) {
                        this.showCategoryTypeChangeConfirmationModal = true;
                    }
                }

                that.categoryOptions = [];
                that.categories.forEach(function (item, index) {
                    if(item.type == category_type) {
                        that.categoryOptions.push({
                            id: item.id,
                            text: item.name
                        })
                    }
                });
            },
            updatePrice: function(categoryId) {
                const categoryData = this.categories.filter(obj => obj.id == categoryId)
                this.conditionalHeadshot = false;
                this.addAnotherVideoAllowed = (this.role === 'admin');
                if (categoryData[0]) {
                    if (categoryData[0].conditional_headshot == 1) {
                        this.conditionalHeadshot = true;
                    }
                    if (categoryData[0].max_video_file_object_uploads > 1) {
                        this.addAnotherVideoAllowed = true;
                    }
                    this.orderinfo.cost = categoryData[0].price;
                }
            },
            inputReadOnly: function (wrapperElementId) {
                const wrapper = `${wrapperElementId} input, ${wrapperElementId} textarea, ${wrapperElementId} select`;
                const inputElements = document.querySelectorAll(wrapper);
                let x = inputElements.length;
                while(x--) {
                    inputElements[x].readOnly = 1;
                }
            },
            titleCaseBlur: function(evt) {
                if(this.role !== 'admin') {
                    var string_split = evt.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < string_split.length; i++) {
                        var j = string_split[i].charAt(0).toUpperCase();
                        string_split[i] = j + string_split[i].substring(1);
                    }
                    evt.target.value = string_split.join(" ");
                    this.orderinfo.program_name = string_split.join(" ");
                }

            },
            titleCase: function(varname, evt) {
                if(this.role !== 'admin') {
                    var start = evt.target.selectionStart;
                    var end = evt.target.selectionEnd;
                    var string_split = evt.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < string_split.length; i++) {
                        var j = string_split[i].charAt(0).toUpperCase();
                        string_split[i] = j + string_split[i].substring(1);
                    }
                    evt.target.value = string_split.join(" ");
                    evt.target.setSelectionRange(start, end);
                }
            },
            deleteImage: function(fieldName) {
                this.orderinfo[fieldName] = '';
                this.updateForm('','',false);
            },
            dynamicClass(input_step) {
                if(this.orderinfo.application_step == -1) {
                    return;
                }
                return;
            },
            applicationStepSelector(input) {
                if(input == this.orderinfo.application_step) {
                    this.orderinfo.application_step = -1;
                } else {
                    this.orderinfo.application_step = input;
                    this.$scrollTo('#order-step-'+(input-1), 700);
                }
            },
            SaveApplicationStep(inputStep) {
                this.inputStepClicked = inputStep
                if(!this.savingData) {
                    this.actualSaveApplicationStep(this.inputStepClicked)
                }
            },
            actualSaveApplicationStep() {
                let app = this;
                if(app.inputStepClicked === app.orderinfo.application_step) {
                    app.orderinfo.application_step = -1;
                    return;
                }
                if(app.updatingForm) {
                    return;
                }
                app.updatingForm = true;

                axios.post('/order/step/update/vp', {
                    'order_id': app.orderinfo.order_id,
                    'application_step': app.inputStepClicked-1
                }).then(function(response) {
                    app.iconArray = response.data.status_icons;
                    if(response.data.errors) {
                        app.errorObject = response.data.errors;

                        if(app.errorObject.details && Object.keys(app.errorObject.details).some(key => key.includes('logline'))) {
                            Object.keys(app.errorObject.details).forEach(function(key) {
                                if(key.includes('logline')) {
                                    let index = key.match(/\d+/)[0];
                                    app.$refs.videoComponent.wordCounterDisplay(index);
                                }
                            });
                        }
                    }

                    // Asset Details and/or Order Details complete, no errors get them to submit.
                    if((app.inputStepClicked == 2) && (_.size(app.errorObject.details) == 0) && (_.size(app.errorObject.assets) == 0)) {
                        app.showOrderAssetsCompletePopup = true;
                    } else if((app.inputStepClicked == 1) && (_.size(app.errorObject.details) == 0)) {
                        app.showOrderDetailsCompletePopup = true;
                    } else {
                        app.$scrollTo('#top-of-form', 700);
                        app.$root.$refs.flash_alert.activateFlashAlert('Fill in all the required fields before proceeding.', 'error');
                        app.showErrorMessage = true;
                    }
                    app.updatingForm = false;

                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }

                    let errorMessage = err.response.data.message;
                    app.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    app.showErrorMessage = true;
                    app.updatingForm = false;
                });
            },
            toggleFunctionality: function(fieldName, value, saveData, form_section) {
                this.orderinfo[fieldName] = value;
                if(this.orderinfo[fieldName] == 1) {
                    this.toggleClass[fieldName] = 'active-yes' + (this.update_allowed[form_section] ? '' : ' no-hover');
                } else if (this.orderinfo[fieldName] == 0) {
                    this.toggleClass[fieldName] = 'active-no' + (this.update_allowed[form_section] ? '' : ' no-hover');
                }
                if(saveData != 'onload') {
                    this.updateForm(form_section, fieldName, false);
                }
            },
            emailSyncWithOrder: function(month) {
                this.emailsStatuses[month] = true;

                let emailIndex = this.orderinfo.emails.findIndex(email => email.month == month);
                if(emailIndex > -1) {
                    this.orderinfo.emails[emailIndex].email_status = 'submitted';
                }
            },
            emailStatusesCreator: function() {
                let that = this;
                this.orderinfo.emails.forEach((email) => {
                    that.hasEmails = true;
                    that.emailsStatuses[email.month] = !that.emailStatusOptionsBeforeCheckout.includes(email.email_status);
                });
            },
            savingVideoData: function(savingTrueorFalse) {
                // This is used to disable the submit button while the video is saving
                this.updatingForm = savingTrueorFalse
            },
            deleteEmailsFromCategoryType: function() {
                let that = this;
                window.axios({
                        method: 'delete',
                        url: this.actionurl_email_delete
                    })
                .then(function(response) {
                    that.showCategoryTypeChangeConfirmationModal = false;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }

                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.showErrorMessage = true;
                });
            },
            revertCategoryChange: function() {
                this.showCategoryTypeChangeConfirmationModal = false;
                this.orderinfo.category_type = 'program';
                this.createCategorySelectList(this.orderinfo.category_type, false);
            },
            submitOrderIssue: function () {
                let that = this;
                window.axios(
                    {
                        method: 'post',
                        url: this.report_issue_url,
                        data: {
                            'submitter_issue_status': this.orderinfo.submitter_issue_status,
                            'submitter_issue_details': this.orderinfo.submitter_issue_details,
                        },
                }).then(function (response) {
                    that.reportOrderIssue = false;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }

                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.showErrorMessage = true;
                    that.reportOrderIssue = false;
                });
            },
            async saveForLater() {
                while (this.updatingForm || this.pendingFormUpdate || this.videoUploadsInProgress) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }

                window.location.href = this.ordersurl;
            },
        },
    }
</script>

<style scoped>
    .success { color:green; }
</style>
