<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g id="_1.-Foundations_Icons_ATAS_Icon_Clock" data-name="1.-Foundations/Icons/ATAS_Icon_Clock">
            <path id="Combined-Shape" class="cls-1"
                  d="M14.64,6.32a20,20,0,1,1-3.2,33.24h0l2.84-2.88a16,16,0,1,0-4.42-5.2,4,4,0,0,1-1.32,5.18,18.87,18.87,0,0,1-2.22-3.3A20,20,0,0,1,14.64,6.32ZM26,14v9.18l5.42,5.4-2.84,2.84L23.18,26A4,4,0,0,1,22,23.18h0V14Z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'clock',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
