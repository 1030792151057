<template>
    <div>
        <div class="entry-info_item">
            <span class="label small-caps">Viewing Platform URL:</span>
            <div class="value" style="text-transform: none" v-if="!inputOpen">
                <span class="internal-text-value d-inline-block">
                    {{ originalViewingPlatformUrl }}
                </span>
                <a href="#" @click.prevent="inputOpen = !inputOpen" class="btn btn-outline-primary text-action p-1" :class="{'ml-3': !!originalViewingPlatformUrl}">
                    <i class="fas fa-fw fa-pencil"></i> {{ editOrAdd }}
                </a>
            </div>
        </div>
        <div v-show="inputOpen">
            <input type="text" v-model="viewingPlatformUrl" @keydown.enter="saveViewingPlatformUrl" />
            <div class="edit-buttons mt-2">
                <button class="btn save" @click="saveViewingPlatformUrl"><save-icon class="svg-black"></save-icon> Save</button>
                <button class="btn btn-default btn-grey cancel ml-2" @click="closeViewingPlatformUrl">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import SaveIcon from "@/Icons/save.vue";

    export default {
        components: {
            SaveIcon,
        },

        props: {
            actionUrl: {
                type: String,
                required: true,
            },
            viewingPlatformUrlLoaded: {
                type: String,
                required: true,
            },
            id: {
                type: Number,
                required: true,
            }
        },

        data() {
            return {
                viewingPlatformUrl: '',
                editOrAdd: 'Add',
                inputOpen: false,
                originalViewingPlatformUrl: '',
            }
        },

        mounted: function() {
            this.originalViewingPlatformUrl = this.viewingPlatformUrlLoaded;
            this.viewingPlatformUrl = this.viewingPlatformUrlLoaded ?? 'https://';
            if (!!this.originalViewingPlatformUrl) {
                this.editOrAdd = 'Edit';
            }
        },

        watch: {
            inputOpen: function (val) {
                if (val) {
                    this.editOrAdd = 'Add';
                } else {
                    if (!!this.originalViewingPlatformUrl) {
                        this.editOrAdd = 'Edit';
                    } else {
                        this.editOrAdd = 'Add';
                    }
                }
            },
        },

        methods: {
            saveViewingPlatformUrl: function() {
                window.axios({
                    method: 'put',
                    url: this.actionUrl,
                    data: {
                        id: this.id,
                        viewing_platform_url: this.viewingPlatformUrl
                    }
                }).then(() => {
                    this.originalViewingPlatformUrl = this.viewingPlatformUrl;
                    this.inputOpen = false;
                    window.location.reload();
                }).catch(function (error) {
                    console.log('This is the error returned', error);
                });
            },

            closeViewingPlatformUrl: function() {
                this.inputOpen = false;
                this.viewingPlatformUrl = this.originalViewingPlatformUrl ?? 'https://';
            }
        },
    }
</script>
