<template>
    <div class="entry-info entryAdmin w-100">
        <div class="entry-info_item" v-if="type == 'event'">
            <span class="label small-caps">Event Title:</span>
            <span class="value">
                {{ order.event_title }}
            </span>
        </div>
        <div class="entry-info_item">
            <span class="label small-caps">Order Status:</span>
            <span class="value status-value-column" v-if="type == 'event'">
                {{ event_status_options.find(x => x.id === order.order_status).text }}
            </span>
            <span class="value status-value-column" v-else>
                {{ order_status_options.find(x => x.id === order.order_status).text }}
            </span>
            <admin-order-status-update
                status-update-type="details"
                :action-url="actionurl_details"
                :order-status="order.order_status"
                :statuses="submission_statuses"
            ></admin-order-status-update>
        </div>

        <div class="entry-info_item" v-if="type == 'event'">
            <span class="label small-caps">Asset Status:</span>
            <span class="value status-value-column" v-if="order.asset_status">
                {{ asset_status_options.find(x => x.id === order.asset_status).text }}
            </span>
            <admin-order-status-update
                status-update-type="assets"
                :action-url="actionurl_assets"
                :order-status="order.asset_status"
                :statuses="asset_statuses"
            ></admin-order-status-update>
            <!-- TODO consider a confirmation modal for the "migrate" action, especially if the event was already synced earlier -->
            <button class="btn btn-outline-primary p-1 ml-3" v-if="order.asset_status == 'building'" @click="migrateEventData()" :disabled="migratingData">
                <i class="fas fa-fw fa-upload"></i> Migrate
            </button>
            <div class="spinner d-inline-block small align-middle ml-3" v-if="migratingData"></div>
        </div>
        <div class="entry-info_item d-flex mt-2 w-66" v-else-if="type == 'order_network' || (type == 'order_vp' && order.category_type == 'program')">
            <span class="label small-caps">Asset Status:</span>
            <div class="d-flex align-items-start w-100">
                <div v-for="email in order.emails" class="w-25">
                    <template v-if="email.month">
                        <div class="asset-status-month-header text-uppercase mb-0">{{ email.month }}</div>
                        <div class="value" :class="'status-color-' + email.email_status">{{ asset_status_options.find(x => x.id === email.email_status).text }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div class="entry-info_item" v-if="order.order_status == 'user_reported'">
            <span class="label small-caps">Submitter Issue:</span>
            <span class="value status-value-column" >
                {{ submitter_issue_status_options.find(x => x.id === order.submitter_issue_status).text }}:
                {{ order.submitter_issue_details }}
            </span>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Team Assigned:</span>
            <span class="value status-value-column" v-if="order.team_assigned_user">
                {{ team_assigned_members[order.team_assigned_user] ?? '' }}
            </span>
            <span class="value status-value-column" v-else>
                <em>Not assigned</em>
            </span>
            <div class="select ml-0">
                <team-assignment-update
                    :team_assigned_user="order.team_assigned_user"
                    :team_assigned_members="team_assigned_members"
                    reload_after_update=true
                    :actionurl_team_assigned="actionurl_team_assigned"
                ></team-assignment-update>
            </div>
        </div>

        <div class="entry-info_item checkbox-wrapper" v-if="type == 'event'">
            <span class="label small-caps">Notifications:</span>
            <div class="d-inline-block align-middle">
                <input type="checkbox" @change="notificationsOff" v-model="order.notifications_off" id="notifications_off" class="form-control notifications_off checkbox">
                <label class="d-flex align-items-center box-checking pl-0 cursor-pointer" for="notifications_off">
                    <checkbox_checked class="svg-24 mr-2" v-if="order.notifications_off == 1"></checkbox_checked>
                    <checkbox_empty class="svg-24 mr-2" v-else></checkbox_empty>
                    <span>Pause Sending Email Notifications</span>
                </label>
            </div>
        </div>
        <div class="entry-info_item" v-if="type === 'event'">
            <span class="label small-caps">Event Date:</span>
            <span class="value">
                {{ order.event_date ?? '' }}
                {{
                    order.time_slot == 'Open'
                        ? (order.time_slot_other ? ' - ' + order.time_slot_other : '')
                        : (order.time_slot ? ' - ' + order.time_slot : '')
                }}
            </span>
        </div>
        <div class="entry-info_item d-flex py-1" v-if="type == 'event'">
            <span class="label small-caps py-1">Email Date:</span>
            <span class="value status-value-column" v-if="order.emails[0].send_at">{{ order.emails[0].send_at }}</span>
            <span class="value status-value-column" v-else><em>No email date set</em></span>
            <template v-if="order.emails[0].send_at">
                <div class="value">
                    <input type="text" name="send_at_time" class="p-1" v-model="order.emails[0].send_at_time">
                </div>
                <div>
                    <a href="#" @click.prevent="saveEmailTime()" class="btn btn-outline-primary ml-3 p-1 btn-icon">
                        <i class="fas fa-fw fa-save"></i> Save
                    </a>
                </div>
            </template>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Payment:</span>
            <span class="value">{{ invoice_status ?? '' }}</span>
        </div>
        <div class="entry-info_item">
            <span class="label small-caps">Price:</span>
            <span class="value">${{ order.cost ?? order.price ?? 0.00 }}</span>
        </div>
        <div class="entry-info_item">
            <span class="label small-caps">Amount Due:</span>
            <span class="value">${{ order.amount_due ?? order.cost ?? order.price ?? 0.00 }}</span>
        </div>
        <div class="entry-info_item">
            <span class="label small-caps">Amount Paid:</span>
            <span class="value">${{ order.amount_paid ?? 0.00 }}</span>
        </div>
        <div class="entry-info_item my-2">
            <span class="label small-caps mt-1" style="vertical-align: top">Invoices:</span>
            <span class="value">
                <div v-for="invoice in invoices" class="my-1" v-if="invoice">
                    #{{ invoice.invoice_number }}
                    <span class="ml-3 small font-weight-bold text-uppercase">{{ invoice.status }}</span>
                    <a class="ml-3 btn btn-outline-primary p-1" :href="'/invoice/download/'+invoice.id"><i class="fas fa-fw fa-download"></i> Download</a>
                    <a v-show="type === 'event'" class="ml-3 small font-weight-bold text-uppercase" :href="'/finance/invoice/'+invoice.id">Edit</a>
                </div>
                <div v-if="type === 'event' && invoices.length === 0" class="mt-1 mb-3">
                    <a class="small font-weight-bold text-uppercase" :href="'/finance/invoice/new?order='+order.order_id+'&initial=1'">
                        <plus-icon svg-class="svg-12" /> Create initial invoice
                    </a>
                </div>
                <div v-if="type === 'event'" class="my-1">
                    <a class="small font-weight-bold text-uppercase" :href="'/finance/invoice/new?order='+order.order_id">
                        <plus-icon svg-class="svg-12" /> Add new invoice
                    </a>
                </div>
            </span>
        </div>
        <admin-change-user v-if="type == 'event'" :your_name="order.submitter_name ?? ''" :your_email="order.user.email ?? ''" :user_id="order.user_id ?? ''" :action_url="user_suggestions_url" :action_url_update="user_change_url" :order_id="order.order_id" :is_admin="role"></admin-change-user>
        <admin-change-user v-else :your_name="order.your_name ?? ''" :your_email="order.user.email ?? ''" :user_id="order.user_id ?? ''" :action_url="user_suggestions_url" :action_url_update="user_change_url" :order_id="order.order_id" :is_admin="role"></admin-change-user>
        <div class="entry-info_item" v-if="type == 'event'">
            <span class="label small-caps">Event Created:</span>
            <span class="value">{{ created_at_display ?? '' }}</span>
        </div>
        <template v-if="type === 'order_vp'">
            <admin-viewing-platform-url
                :action-url="updateurl"
                :viewing-platform-url-loaded="order.viewing_platform_url"
                id="order.id"
            ></admin-viewing-platform-url>
        </template>
        <admin-internal-notes :action-url="updateurl" :internal-note-loaded="order.internal_note" :id="order.id"></admin-internal-notes>
        <div class="entry-info_item" v-if="order.needs_more_info_text">
            <span class="label small-caps">Needs More Info:</span>
            <span class="value">
                {{ order.needs_more_info_text ?? '' }}
            </span>
        </div>
        <div class="entry-info_item" v-if="order.assets_needs_more_info_text">
            <span class="label small-caps mr-3">Assets Needs More Info:</span>
            <span class="value">
                {{ order.assets_needs_more_info_text ?? '' }}
            </span>
        </div>

    </div>
</template>

<script>
    import PlusIcon from "@/Icons/plus.vue";
    import Checkbox_checked from "@/Icons/checkbox_checked.vue";
    import Checkbox_empty from "@/Icons/checkbox_empty.vue";
    import AdminViewingPlatformUrl from "@/components/AdminViewingPlatformUrl.vue";

    export default {
        components: {AdminViewingPlatformUrl, Checkbox_empty, Checkbox_checked, PlusIcon},
        props: {
            type: String,
            order: Array,
            actionurl_details: String,
            actionurl_assets: String,
            actionurl_send_at_time: String,
            actionurl_team_assigned: String,
            updateurl: String,
            user_change_url: String,
            submission_statuses: Array,
            asset_statuses: Array,
            team_assigned_members: Array,
            invoices: Array,
            user_suggestions_url: String,
            migrateurl: String,
        },
        created() {
            if(this.order.event_date) {
                // Set event_date to m-d-Y format
                let eventDate = new Date(this.order.event_date);
                let month = eventDate.getMonth() + 1;
                let day = eventDate.getDate();
                let year = eventDate.getFullYear();
                this.event_date_display = eventDate.toLocaleString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
            }

            if(this.order.created_at) {
                // Set created_at to m-d-Y format
                let createdDate = new Date(this.order.created_at);
                this.created_at_display = createdDate.toLocaleString('en-us', { month: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { day: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { year: 'numeric' }) + ' - ' + createdDate.toLocaleString('en-us', { hour: 'numeric', minute: 'numeric'});
            }

        },
        data() {
            return {
                order_status_options: [{
                    text: "In Progress",
                    id: "in_progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Approved For Build",
                    id: "approved_for_build"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Send To Viewing Platform",
                    id: "approved"
                }, {
                    text: "Needs More Information",
                    id: "need_more_info"
                },
                {
                    text: "User Reported",
                    id: "user_reported"
                },
                {
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live - Checked",
                    id: "live_checked"
                },
                {
                    text: "Order Completed",
                    id: "order_completed"
                }],
                event_status_options: [{
                    text: "In Progress",
                    id: "in_progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Approved For Build",
                    id: "approved_for_build"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Order Approved",
                    id: "approved"
                }, {
                    text: "Needs More Information",
                    id: "need_more_info"
                },
                {
                    text: "User Reported",
                    id: "user_reported"
                },
                {
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live - Checked",
                    id: "live_checked"
                },
                {
                    text: "Order Completed",
                    id: "order_completed"
                }],
                asset_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Assets Submitted", id: "submitted" },
                    { text: "Internal Review", id: "internal_review" },
                    { text: "Approved For Go Live", id: "approved" },
                    { text: "Need More Information", id: "need_more_info" },
                    { text: "Build Underway", id: "building" },
                    { text: "Build Complete", id: "build_complete" },
                    { text: "Published & Sent", id: "published" },
                    { text: "Canceled", id: "canceled" },
                ],
                submitter_issue_status_options: [
                    { id: "add_or_delete_program", text: "Add or Delete Program"},
                    { id: "logo_or_key_art_update", text: "Logo or Key Art Update"},
                    { id: "email_send_date", text: "Email Send Date"},
                    { id: "title_update", text: "Title Update"},
                    { id: "panelist_moderator_update", text: "Panelist/Moderator Update"},
                    { id: "checkin_process_update", text: "Check-In Process Update"},
                    { id: "parking_update", text: "Parking Update"},
                    { id: "other_update", text: "Other Update"}
                ],
                created_at_display: '',
                migratingData: false,
                sendAtTime: this.order.emails[0]?.send_at_time
            }
        },
        methods: {
            notificationsOff() {
                window.axios
                    .put(this.updateurl, {
                        notifications_off: this.order.notifications_off
                    })
                    .then((response) => {
                        let dataBack = response.data;
                        location.reload();
                    });
            },
            migrateEventData() {
                this.migratingData = true;
                window.axios
                    .post(this.migrateurl)
                    .then((response) => {
                        this.migratingData = false;
                        this.$root.$refs.flash_alert.activateFlashAlert('Event Successfully Migrated', 'success');
                    }).catch(err => {
                        this.migratingData = false;
                        this.$root.$refs.flash_alert.activateFlashAlert(err.response.data.message, 'error');
                    });
            },
            saveEmailTime: function() {
                window.axios
                    .put(this.actionurl_send_at_time, {
                        send_at: this.order.emails[0].send_at,
                        send_at_time: this.order.emails[0].send_at_time
                    })
                    .then((response) => {
                        let dataBack = response.data;
                        location.reload();
                    });
            },
        }
    }
</script>
