<template>
    <div class="form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0">
        <div class="form-group-beside_inside row" :class="underlineFieldClass">
            <label class="col-2 col-md-6">
                <div class="closed-caption-label" for="closed_caption">{{ display_name }} <em class="yellow no-style" v-if="required == 1">*</em></div>
                <div class="description wysiwyg-content" v-html="description"></div>
            </label>
            <div class="col-10 col-md-6 pl-3" v-if="update_allowed">
                <a href="#" class="btn btn-primary displaybloc" @click.prevent="activateDropzone(machine_name, $event)" v-if="update_allowed && filename == ''"><upload class="svg-24"></upload> Upload</a>
                <div class="input-wrapper" v-show="update_allowed && filename == '' && showDropzone">
                    <vue-dropzone class="video-placeholder" :ref="'dropzone'+machine_name" :id="'dropzone'+machine_name" :options="dropzoneOptions" v-on:vdropzone-success="successCallback"></vue-dropzone>
                </div>
                <div id="closed_caption" class="filename no-border d-flex justify-content-between align-items-center"  v-if="filename != '' && display_image_or_filename != 'image'" style="margin-left: -36px;">
                    <div class="pr-3 d-flex align-items-center" @click.prevent="activateDropzone(machine_name, $event)">
                        <file-icon class="mr-2"></file-icon>
                        <div>{{ filename }}</div>
                    </div>
                    <a class="btn btn-delete pull-right min-delete-button-width" @click.prevent="activateDeleteFileUploadPopup()" v-if="filename != '' && update_allowed"><trash class="svg-24"></trash>Delete</a>
                </div>
                <div v-if="displayImage != '' && filename != '' && display_image_or_filename == 'image'">
                    <a class="btn btn-delete pull-right min-delete-button-width" @click.prevent="activateDeleteFileUploadPopup()" v-if="filename != '' && update_allowed"><trash class="svg-24"></trash>Delete</a>
                    <div class="small-img d-inline-block" @click.prevent="activateDropzone(machine_name, $event)">
                        <img :src="displayImage">
                    </div>
                </div>
            </div>
            <div class="col-10 col-md-6 pl-3" v-else>
                <div id="closed_caption" class="filename no-border d-flex justify-content-between align-items-center"  v-if="filename != '' && display_image_or_filename != 'image'" style="margin-left: -36px;">
                    <div class="pr-3 d-flex align-items-center">
                        <file-icon class="mr-2"></file-icon>
                        <div>{{ filename }}</div>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="modalTriggerConfirmDelete" @close="modalTriggerConfirmDelete = false">
            <h3 slot="header">Removing {{ display_image_or_filename }}</h3>
            <div class="content" slot="body">
                <div>Are you sure you want to remove this {{ display_image_or_filename }}? </div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="btn btn-cancel" @click.prevent="modalTriggerConfirmDelete = false">Cancel</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteFile()">Yes, Remove</button>
            </div>
        </modal>

        <modal v-if="modalProblemUploading" @close="modalProblemUploading = false">
            <h3 slot="header">Problem Uploading File</h3>
            <div class="content" slot="body">
                <div>Incorrect image size. Image should be {{ image_width }} x {{ image_height }} pixels. </div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="pull-right btn btn-primary" @click.prevent="modalProblemUploading = false">Close</button>
            </div>
        </modal>

    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import modal from './Modal.vue';
import upload from '../Icons/upload.vue';
import trash from '../Icons/trash.vue';
import FileIcon from "@/Icons/file.vue";
import Download from "@/Icons/download.vue";

export default {
    components: {Download, FileIcon, modal, vueDropzone, upload, trash },
    props: [
        'entry',
        'file_types',
        'saved_filename',
        'description',
        'display_name',
        'required',
        'machine_name',
        'update_allowed',
        'role',
        'action_url',
        'display_image_or_filename',
        'image_width',
        'image_height',
        'image_width_only',
    ],
    data() {
        return {
            filename: this.saved_filename,
            modalTriggerConfirmDelete: false,
            modalProblemUploading: false,
            showDropzone: false,
            underlineFieldClass: '',
            displayImage: '',
            dropzoneOptions: {
                url: this.action_url,
                paramName: this.machine_name,
                acceptedFiles: this.file_types,
                maxFilesize: 30000, // 30GB
                timeout: 86400000, // 24hrs
                headers: { 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content },
                complete: this.completeUploadShowImage,
                error: function(file, message, xhr) {
                    console.log(file, 'file', message, 'message', xhr, 'xhr');
                    let errorMessage = message + ' There was a problem uploading your file.';
                    this.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    this.$scrollTo('#top-of-form', 700);
                }.bind(this),
                accept: this.acceptCallback,
            }
        }
    },
    created() {
        if(this.entry[this.machine_name] != undefined) {
            this.displayImage = this.entry[this.machine_name];
        }
        this.filename = this.filename?.replace(/^original_/, '');
    },
    methods: {
        completeUploadShowImage: function(file) {
            if(this.display_image_or_filename === 'image') {
                this.displayImage = file.dataURL;
            }
            this.$emit('saveForValidation', this.filename, this.machine_name, this.displayImage);
        },
        activateDropzone: function(machine_name, $event) {
            let valCodeButton = document.getElementById('dropzone'+machine_name);
            valCodeButton.click();
        },
        activateDeleteFileUploadPopup: function() {
            let app = this;
            app.modalTriggerConfirmDelete = true;
        },
        deleteFile: function() {
            let app = this;
            window.axios(
                {
                    method: 'POST',
                    data: {
                        [app.machine_name]: '',
                    },
                    url: app.action_url,
                }).then(function(response) {
                app.modalTriggerConfirmDelete = false;
                window.location.reload();
            }).catch(function (err) {
                let errorMessage = err.response.status + ' status error. There was a problem deleting your file.';
                app.$parent.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
            });
        },
        underlineFields() {
            let app = this;
            let submitFormBlock = true;
            // If required and filename is blank, don't submit the form!
            if((app.required == 1) && (app.filename == '')) {
                submitFormBlock = false;
                app.underlineFieldClass = ' required';
            }
            return submitFormBlock;
        },
        successCallback: function(file, response) {
            this.filename = file.upload.filename;
        },
        acceptCallback: function(file, $event) {
            let app = this;
            if(app.display_image_or_filename == 'image') {
                this.$refs['dropzone'+app.machine_name].dropzone.on("thumbnail", function(file) {
                    if (app.image_width_only && (file.width != app.image_width || file.height > app.image_height)) {
                        app.modalProblemUploading = true;
                        app.$refs['dropzone' + app.machine_name].removeAllFiles(true);
                        app.showDropzone = false;
                    } else if (file.width != app.image_width && file.height != app.image_height) {
                        app.modalProblemUploading = true;
                        app.$refs['dropzone' + app.machine_name].removeAllFiles(true);
                        app.showDropzone = false;
                    } else {
                        app.$refs['dropzone'+app.machine_name].dropzone.processFile(file);
                    }
                });
            } else {
                app.$refs['dropzone'+app.machine_name].dropzone.processFile(file);
            }
            this.showDropzone = true;
        }
    }
}
</script>
