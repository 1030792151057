<template>
    <div @click.stop>
        <select id="team-assigned-select"  class="plain-select" style="font-size: medium;" v-model="team_assigned_user" @change.stop="changeTeamAssigned()">
            <option value="" ></option>
            <option v-for="(value, key) in team_assigned_members" :value="key" v-text="value"></option>
        </select>
    </div>
</template>

<script>
    import PlusIcon from "@/Icons/plus.vue";
    import Checkbox_checked from "@/Icons/checkbox_checked.vue";
    import Checkbox_empty from "@/Icons/checkbox_empty.vue";

    export default {
        components: {Checkbox_empty, Checkbox_checked, PlusIcon},
        props: {
            team_assigned_user: String,
            team_assigned_members: Array,
            actionurl_team_assigned: String,
            reload_after_update: Boolean
        },
        created() {

        },
        data() {
            return {

            }
        },
        methods: {
            changeTeamAssigned: function() {
                window.axios
                    .put(this.actionurl_team_assigned, {
                        team_assigned_user: this.team_assigned_user
                    })
                    .then((response) => {
                        let dataBack = response.data;
                        if(this.reload_after_update !== 'false') {
                            location.reload();
                        }
                    });
            },

        }
    }
</script>
